import { Link, NavLink } from "react-router-dom";
import './HeaderMain.style.scss'
import { useEffect, useRef, useState } from "react";
import {history} from '../../../_helpers/history'
import { apiCaller } from "../../../_helpers";
import { listHeader } from "../../../_constants/headerNews";
export default function HeaderMain() {
  const [isHoverLogout, setIsHoverLogout] = useState(false)
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false)
  const menuMobileRef = useRef();

  const handleLogout = async () => {
    await apiCaller('/logout', "POST", {})
    window.location.href = "/login";
    localStorage.removeItem("authentication");
    localStorage.removeItem("access_token");
    setIsOpenMenuMobile(false)
  }

  const renderListHeader = () => {
    return (
      <>
        {listHeader.map(item => (
          <NavLink
            exact={item?.exact}
            to={item.href}
            className={isActive =>
              "header-item" + (isActive ? " header-item-selected" : "")
            }
            onClick={() => setIsOpenMenuMobile(false)}
            key={item.id}
          >
            {item.name}
          </NavLink>
        ))}
      </>
    )
  }

  const handleClickOutside = (event) => {
    if (menuMobileRef.current && !menuMobileRef.current.contains(event.target)) {
      setIsOpenMenuMobile(false);
    }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);

  return (
    <div className="header-main-container">
      <div className="sunE-container">
        <div className="header-main-content">
          <div className="logo">
            <Link to={`/`}>
              <img src={"/assets/images/logo.png"} alt={"Logo"} />
            </Link>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
            <div className="list-header disable-mobile">
              {renderListHeader()}
            </div>
            <div className="btn-logout disable-mobile" onMouseOver={() => setIsHoverLogout(true)} onMouseOut={() => setIsHoverLogout(false)} onClick={handleLogout}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8m4-9l-4-4m4 4l-4 4m4-4H9"/></svg>
              {isHoverLogout && <span className="btn-logout-text">Đăng xuất</span>}
            </div>
          </div>
          <div className="enable-mobile">
            <div className="menu-mobile" ref={menuMobileRef}>
              <svg onClick={() => setIsOpenMenuMobile(!isOpenMenuMobile)} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="#404041" d="M3 18v-2h18v2zm0-5v-2h18v2zm0-5V6h18v2z"/></svg>
              {isOpenMenuMobile && <div className="list-header-mobile">
                {renderListHeader()}
                <div className="header-item" onClick={handleLogout}>Đăng xuất</div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}