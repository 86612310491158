export const TypeHeaderNewsItem = {
  HOME: "",
  INTRODUCTION: "introduction",
  COURSES: "courses",
  FEE: "fee",
  MOCK_TEST: "mock_test",
  TEACHER: "teacher_news",
  PARENT: "parent_news",
  LOGIN: "login_news",
  POLICY: "policy",
  TARIFF: "tariff",
};

export const NameTitleNewsItem = {
  HOME: "Trang chủ",
  INTRODUCTION: "Giới thiệu",
  COURSES: "Khóa học",
  FEE: "Học phí",
  MOCK_TEST: "Luyện thi",
  CONTACT: "Liên hệ",
  PROFILE: "Hồ sơ",
  // LOGIN: "login_news",
};

export const listHeader = [
  {
    id: 1,
    name: 'Thông tin chung',
    href: '/',
    exact: true
  },
  {
    id: 2,
    name: 'Danh sách khách hàng',
    href: '/customers',
  },
  {
    id: 3,
    name: 'Danh sách tuyến dưới',
    href: '/grants'
  }
]