import React, { useEffect, useState, useLayoutEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import {
  RouteRedirectToAdmin,
  RouteRedirectToLogin,
} from "./_components/Router";
import { alertActions } from "./_actions";
import { history } from "./_helpers";
import { useDispatch, useSelector } from "react-redux";
import {LoginPage} from './_containers/LoginPage/index'
import { ForgotPasswordPage } from "./_containers/ForgotPasswordPage";
import RegisterPage from "./_containers/RegisterPage";
import HomePage from "./_containers/HomePage";
import ListCustomer from "./_containers/ListCustomer";
import ListGrantPage from "./_containers/ListGrantPage";

function App() {
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);

  useEffect(() => {
    history.listen(() => {
      dispatch(alertActions?.clear());
    });
  }, []);

  return (
    <div className="rel">
      <div className="loading hide">
        <div className="loader"></div>
      </div>
      <div className="loading-side hide"></div>
      <Router path="/" history={history}>
        <Switch>
          <RouteRedirectToAdmin
            path="/login"
            exact={true}
            component={LoginPage}
          />
          <RouteRedirectToAdmin
            path="/forgot-password"
            exact={true}
            component={ForgotPasswordPage}
          />
          <RouteRedirectToAdmin
            path="/dk"
            exact={true}
            component={RegisterPage}
          />
          <RouteRedirectToLogin
            path="/"
            exact={true}
            component={HomePage}
            authentication={authentication}
          />
          <RouteRedirectToLogin
            path="/customers"
            exact={true}
            component={ListCustomer}
            authentication={authentication}
          />
          <RouteRedirectToLogin
            path="/grants"
            exact={true}
            component={ListGrantPage}
            authentication={authentication}
          />
        <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
