import { useEffect, useState } from "react";
import InputText from "../../_components/Auth/InputText";
import HeaderMain from "../../_components/Header/HeaderMain";
import InputDate from "../../_components/Auth/InputDate";
import { apiCaller } from "../../_helpers";
import { configConstants } from "../../_constants";
import dayjs from "dayjs";
import './ListGrantPage.style.scss'
import InputRadio from "../../_components/Auth/InputSelect";
import { useSelector } from "react-redux";
// role agents
export default function ListGrantPage() {
  const authentication = useSelector((state) => state.authentication);
  const [listLevel] = useState(Array(authentication?.role === 'agents' ? 2 : 9).fill(0).map((_, i) => ({
    title: `Cấp ${i + 1}`,
    value: (Number(authentication?.grant) + (i + 1)).toString()
  })))
  const [filter, setFilter] = useState({
    keyword: '',
    up_grant: '',
    grant: null,
    start_date: '',
    end_date: '',
    agents_code: ''
  })
  const [errFilter, setErrFilter] = useState({
    keyword: '',
    up_grant: '',
    grant: null,
    start_date: '',
    end_date: '',
    agents_code: ''
  })
  const [listGrant, setListGrant] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isFilter, setIsFilter] = useState(false)

  const getData = async (dataFilter) => {
    setIsLoading(true)
    const endPoint = '/agent/agent_of_general?' +
      (!!dataFilter?.keyword ? `keyword=${encodeURIComponent(dataFilter.keyword)}&` : '') +
      (!!dataFilter?.start_date ? `start_date=${dayjs(dataFilter.start_date).format('YYYY-MM-DD')}&` : '') +
      (!!dataFilter?.end_date ? `end_date=${dayjs(dataFilter.end_date).format('YYYY-MM-DD')}&` : '') +
      (!!dataFilter?.grant ? `grant=${dataFilter.grant?.value}&` : '') +
      (!!dataFilter?.up_grant ? `up_grant=${encodeURIComponent(dataFilter.up_grant)}` : '') +
      (!!dataFilter?.agents_code ? `agents_code=${encodeURIComponent(dataFilter.agents_code)}` : '');

    try {
      const res = await apiCaller(endPoint, 'GET', {}, null, false, configConstants.API_URL_SETEST, true, true)
      setIsLoading(false)
      if(res?.status) {
        setListGrant(res?.data)
      }
    } catch (err) {
      setIsLoading(false)
    }
  }

  const changeFilter = (key, value) => {
    setFilter(prev => ({
      ...prev,
      [key]: value
    }))
    setErrFilter(prev => ({
      ...prev,
      [key]: ''
    }))
  }

  const validateFilter = () => {
    return Object.values(filter).some(item => typeof item === 'object' ? !!item : !!item?.trim())
  }

  const handleCancelFilter = () => {
    const iniFilter = {
      keyword: '',
      up_grant: '',
      grant: null,
      start_date: '',
      end_date: '',
      agents_code: ''
    }
    setErrFilter(iniFilter)
    setFilter(iniFilter)
    if(!isFilter) {
      return;
    }
    setIsFilter(false)
    getData(iniFilter)
  }

  const handleFilter = () => {
    if(!validateFilter()) return;
    let isCanFilter = true;
    if(!!filter.keyword.trim() && filter.keyword.trim().length < 3) {
      setErrFilter(prev => ({
        ...prev,
        keyword: 'Nhập tối thiểu 3 kí tự'
      }))
      isCanFilter = false
    }
    if(!!filter.up_grant.trim() && filter.up_grant.trim().length < 3) {
      setErrFilter(prev => ({
        ...prev,
        up_grant: 'Nhập tối thiểu 3 kí tự'
      }))
      isCanFilter = false
    }
    if(!!filter.agents_code.trim() && filter.agents_code.trim().length < 3) {
      setErrFilter(prev => ({
        ...prev,
        agents_code: 'Nhập tối thiểu 3 kí tự'
      }))
      isCanFilter = false
    }
    if(!!filter.start_date && !filter.end_date) {
      setErrFilter(prev => ({
        ...prev,
        end_date: 'Ngày kết thúc không được để trống'
      }))
      isCanFilter = false;
    }
    if(!filter.start_date && !!filter.end_date) {
      setErrFilter(prev => ({
        ...prev,
        start_date: 'Ngày bắt đầu không được để trống'
      }))
      isCanFilter = false;
    }
    if(!!filter.start_date && !!filter.end_date && new Date(filter.start_date).getTime() > new Date(filter.end_date).getTime()) {
      setErrFilter(prev => ({
        ...prev,
        end_date: 'Ngày kết thúc không được nhỏ hơn ngày bắt đầu'
      }))
      isCanFilter = false;
    }
    if(!isCanFilter) return;
    
    setIsFilter(true)
    getData(filter)
  }

  useEffect(() => {
    getData(filter)
  }, [])

  const renderIconKeyWord = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 16 16"><path fill="#404041" fill-rule="evenodd" d="M0 2.965C0 1.88.88 1 1.965 1h2.807c1.085 0 1.965.88 1.965 1.965v.561c0 1.086-.88 1.965-1.965 1.965H1.965A1.965 1.965 0 0 1 0 3.526zm1.965-.28a.28.28 0 0 0-.28.28v.561a.28.28 0 0 0 .28.281h2.807a.28.28 0 0 0 .28-.28v-.562a.28.28 0 0 0-.28-.28zm6.175.561c0-.465.377-.842.842-.842h6.176a.842.842 0 1 1 0 1.684H8.982a.842.842 0 0 1-.842-.842M.28 8.298c0-.465.378-.842.843-.842H11.79a.842.842 0 1 1 0 1.684H1.123a.842.842 0 0 1-.842-.842M.28 13.35c0-.464.378-.841.843-.841h13.474a.842.842 0 1 1 0 1.684H1.123a.842.842 0 0 1-.842-.842" clip-rule="evenodd"/><path fill="#404041" d="M14.877 9.14a.842.842 0 1 0 0-1.684a.842.842 0 0 0 0 1.684"/></svg>
    )
  }

  const renderIconDate = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 36 36"><path fill="#404041" d="M32.25 6H29v2h3v22H4V8h3V6H3.75A1.78 1.78 0 0 0 2 7.81v22.38A1.78 1.78 0 0 0 3.75 32h28.5A1.78 1.78 0 0 0 34 30.19V7.81A1.78 1.78 0 0 0 32.25 6" class="clr-i-outline clr-i-outline-path-1"/><path fill="#404041" d="M8 14h2v2H8z" class="clr-i-outline clr-i-outline-path-2"/><path fill="#404041" d="M14 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-3"/><path fill="#404041" d="M20 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-4"/><path fill="#404041" d="M26 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-5"/><path fill="#404041" d="M8 19h2v2H8z" class="clr-i-outline clr-i-outline-path-6"/><path fill="#404041" d="M14 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-7"/><path fill="#404041" d="M20 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-8"/><path fill="#404041" d="M26 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-9"/><path fill="#404041" d="M8 24h2v2H8z" class="clr-i-outline clr-i-outline-path-10"/><path fill="#404041" d="M14 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-11"/><path fill="#404041" d="M20 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-12"/><path fill="#404041" d="M26 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-13"/><path fill="#404041" d="M10 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1" class="clr-i-outline clr-i-outline-path-14"/><path fill="#404041" d="M26 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1" class="clr-i-outline clr-i-outline-path-15"/><path fill="#404041" d="M13 6h10v2H13z" class="clr-i-outline clr-i-outline-path-16"/><path fill="none" d="M0 0h36v36H0z"/></svg>
    )
  }

  const renderIconUpGrant = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50"><path fill="#404041" d="M14.237 39.5H44.72V13.419H14.237zm15.489-23.485l10.99 9.598h-2.769v11.516h-6.436V29h-4.065v8.129H21.35V25.613h-2.84zM10.85 6.984V1.018H4.076V50h6.774V10.033h35.226V6.984z"/></svg>
    )
  }

  const renderIconLevel = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 48 48"><g fill="none" stroke="#404041" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"><path d="M24 42L4 18.5L9.695 6h28.61L44 18.5z"/><path d="m32 18l-8 9l-8-9"/></g></svg>
    )
  }

  const renderIconAgency = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50"><path fill="#404041" d="M46 14H34v-3.976C34 7.173 32.159 4 29.311 4h-9.592C16.868 4 15 7.173 15 10.024V14H4c-1.1 0-2 .9-2 2v29c0 1.1.9 2 2 2h42c1.1 0 2-.9 2-2V16c0-1.1-.9-2-2-2M30 44H19v-1.067c0-.023.613-.053.906-.088s.55-.094.761-.176c.375-.141.795-.343.948-.606S22 41.45 22 41.017v-10.23c0-.41-.248-.771-.436-1.081s-.499-.56-.78-.747c-.211-.141-.359-.275-.787-.404S19 28.343 19 28.308v-1.283l8.175-.457l-.175.263v13.957c0 .41.316.759.492 1.046s.542.501.87.642c.234.105.485.199.767.281s.871.14.871.176zm-9.381-23.761c0-.891.343-1.652 1.028-2.285s1.503-.949 2.452-.949s1.764.316 2.443.949s1.02 1.395 1.02 2.285s-.343 1.649-1.028 2.276s-1.497.94-2.435.94c-.949 0-1.767-.313-2.452-.94s-1.028-1.385-1.028-2.276M31 14H18v-3.976C18 8.957 19.08 7 20.147 7h8.052C29.264 7 31 8.957 31 10.024z"/></svg>
    )
  }

  return (
    <div className="bg-main rel">
      <HeaderMain />
      <div className="body-home-container">
        <div className="sunE-container">
          <div className="list-grant-main-container">
            <div className="filter-container">
              <div className="filter-form">
                <div className="filter-form-row">
                  <div className="keyword-filter-form">
                    <InputText
                      classNameContainer='input-container'
                      className='input-base-filter'
                      value={filter.keyword}
                      setValue={(text) => changeFilter('keyword', text)}
                      type="text"
                      name="keyword"
                      placeholder="Nhập tên / số điện thoại / email"
                      renderLabelIcon={renderIconKeyWord}
                      errorText={errFilter.keyword}
                      typeErrText='underAbsolute'
                      errorAbsolute={true}
                      onKeyDown={(e) => {
                        if(e?.which === 13) handleFilter()
                      }}
                    />
                  </div>
                  <div className="grant-level-filter-form">
                    <div>
                      <InputText
                        classNameContainer='input-container'
                        className='input-base-filter'
                        value={filter.up_grant}
                        setValue={(text) => changeFilter('up_grant', text)}
                        type="text"
                        name="up_grant"
                        placeholder="Nhập mã tuyến trên"
                        renderLabelIcon={renderIconUpGrant}
                        errorText={errFilter.up_grant}
                        typeErrText='underAbsolute'
                        errorAbsolute={true}
                        onKeyDown={(e) => {
                          if(e?.which === 13) handleFilter()
                        }}
                      />
                    </div>
                    <div>
                      <InputText
                        classNameContainer='input-container'
                        className='input-base-filter'
                        value={filter.agents_code}
                        setValue={(text) => changeFilter('agents_code', text)}
                        type="text"
                        name="agents_code"
                        placeholder="Nhập mã đại lý"
                        renderLabelIcon={renderIconAgency}
                        errorText={errFilter.agents_code}
                        typeErrText='underAbsolute'
                        errorAbsolute={true}
                        onKeyDown={(e) => {
                          if(e?.which === 13) handleFilter()
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-form-row-second">
                  <InputRadio
                    classNameContainer='input-container'
                    className='input-base-filter'
                    options={listLevel}
                    value={filter.grant}
                    setValue={(option) => changeFilter('grant', option)}
                    name="grant"
                    renderLabelIcon={renderIconLevel}
                    placeholder={"Chọn cấp đại lý"}
                  />
                  <div className="date-filter-form">
                    <div style={{flex: 1}}>
                      <InputDate
                        classNameContainer='input-container'
                        className='input-base-filter'
                        value={filter.start_date}
                        setValue={(date) => changeFilter('start_date', date)}
                        name="start_date"
                        renderLabelIcon={renderIconDate}
                        placeholder={"Từ ngày"}
                        maxDate={new Date()}
                        errorText={errFilter.start_date}
                        typeErrText='underAbsolute'
                        errorAbsolute={true}
                        withPortal
                      />
                    </div>
                     <div style={{flex: 1}}>
                       <InputDate
                          classNameContainer='input-container'
                          className='input-base-filter'
                          value={filter.end_date}
                          setValue={(date) => changeFilter('end_date', date)}
                          name="end_date"
                          renderLabelIcon={renderIconDate}
                          placeholder={"Đến ngày"}
                          maxDate={new Date()}
                          errorText={errFilter.end_date}
                          typeErrText='underAbsolute'
                          errorAbsolute={true}
                          withPortal
                        />
                     </div>
                  </div>
                  <div className="filter-action">
                    <button className="btn-cancel-filter btn-filter" onClick={handleCancelFilter}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><path fill="#4b4a4a" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10s10-4.47 10-10S17.53 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m3.59-13L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41z"/></svg>
                      Hủy
                    </button>
                    <button className="btn-line-blue btn-filter" onClick={handleFilter} disabled={!validateFilter()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/><path fill="#ffffff" d="M10.5 2a8.5 8.5 0 1 0 5.262 15.176l3.652 3.652a1 1 0 0 0 1.414-1.414l-3.652-3.652A8.5 8.5 0 0 0 10.5 2M4 10.5a6.5 6.5 0 1 1 13 0a6.5 6.5 0 0 1-13 0"/></g></svg>
                      Tìm kiếm
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="list-grant-container">
              {!!listGrant.length && <p className="total-grant">{`Danh sách tuyến dưới: ${listGrant.length}`}</p>}
              <div className="list-grant">
                {listGrant?.map(item => (
                  <div className="grant-item" key={item?.id}>
                    <p className="grant-name">{item?.name + ` (${item?.agents_code})`}</p>
                    <div className="grant-row">
                      <div className="grant-info">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="#00B9B7" d="M21 10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-.1a6.887 6.887 0 0 0 0 9.8c2.73 2.7 7.15 2.7 9.88 0c1.36-1.35 2.04-2.92 2.04-4.9h2c0 1.98-.88 4.55-2.64 6.29c-3.51 3.48-9.21 3.48-12.72 0c-3.5-3.47-3.53-9.11-.02-12.58a8.987 8.987 0 0 1 12.65 0L21 3zM12.5 8v4.25l3.5 2.08l-.72 1.21L11 13V8z"/></svg>
                        <span>{dayjs(item?.created_date).format('DD/MM/YYYY')}</span>
                      </div>
                      <div className="grant-info">
                        <span>Cấp đại lý:</span>
                        <span style={{fontWeight: 700}}>{item?.grant -authentication?.grant}</span>
                      </div>
                    </div>
                    <div className="grant-row">
                      <div className="grant-info">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 256 256"><path fill="#00B9B7" d="m224 154.8l-47.09-21.11l-.18-.08a19.94 19.94 0 0 0-19 1.75a13 13 0 0 0-1.12.84l-22.31 19c-13-7.05-26.43-20.37-33.49-33.21l19.06-22.66a12 12 0 0 0 .85-1.15a20 20 0 0 0 1.66-18.83a1.4 1.4 0 0 1-.08-.18L101.2 32a20.06 20.06 0 0 0-20.78-11.85A60.27 60.27 0 0 0 28 80c0 81.61 66.39 148 148 148a60.27 60.27 0 0 0 59.85-52.42A20.06 20.06 0 0 0 224 154.8M176 204A124.15 124.15 0 0 1 52 80a36.29 36.29 0 0 1 28.48-35.54l18.82 42l-19.16 22.82a12 12 0 0 0-.86 1.16A20 20 0 0 0 78 130.08c9.42 19.28 28.83 38.56 48.31 48a20 20 0 0 0 19.69-1.45a12 12 0 0 0 1.11-.85l22.43-19.07l42 18.81A36.29 36.29 0 0 1 176 204"/></svg>
                        <span>{item?.phone}</span>
                      </div>
                      <div className="grant-info">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 50 50"><path fill="#00B9B7" d="M14.237 39.5H44.72V13.419H14.237zm15.489-23.485l10.99 9.598h-2.769v11.516h-6.436V29h-4.065v8.129H21.35V25.613h-2.84zM10.85 6.984V1.018H4.076V50h6.774V10.033h35.226V6.984z"/></svg> */}
                        <span>Mã tuyến trên:</span>
                        <span style={{fontWeight: 700}}>{item?.up_grant}</span>
                      </div>
                    </div>
                    <div className="grant-row grant-row-mobile">
                      <div className="grant-info">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="#00B9B7" d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0l-8 5l-8-5zm0 12H4V8l8 5l8-5z"/></svg>
                        <span>{item?.email}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {!listGrant.length && !isLoading && <p className="empty-text">Không có dữ liệu.</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}