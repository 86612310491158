import { useState } from "react";
import HeaderMain from "../../_components/Header/HeaderMain";
import './HomePage.style.scss'
import { homeType } from "../../_constants/home_type";
import { useSelector } from "react-redux";
import moment from "moment"

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export default function HomePage() {
  const [typeCopy, setTypeCopy] = useState('')
  const [isCopied, setIsCopied] = useState({
    [homeType.LANDING]: false,
    [homeType.REGISTER]: false, 
  })
  const authentication = useSelector((state) => state.authentication);

  const handleCopy = (type, value) => {
    const link = value?.trim()?.substring(0,5)?.includes('http') ? value : `https://${value?.trim()}`
    navigator.clipboard.writeText(link)
    if (!!isCopied?.[type]) {
      return;
    }
    setIsCopied(prev => ({
      ...prev,
      [type]: true
    }));
    setTimeout(() => {
      setIsCopied(prev => ({
        ...prev,
        [type]: false
      }));
    }, 3000)
  }

  const IconCopy = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><g fill="none"><path fill="#00B9B7" d="M8 7h12v12a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2z" opacity="0.16"/><path stroke="#00B9B7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 3H4v13"/><path stroke="#00B9B7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12v12a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2z"/></g></svg>
    )
  }

  const IconTick = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#00B9B7" d="M21 7L9 19l-5.5-5.5l1.41-1.41L9 16.17L19.59 5.59z"/></svg>
    )
  }

  const renderIconInfoAgency = (type) => {
    switch (type) {
      case 'birthday': 
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="#404041" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M4 16.5V20a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-3.5M3 14v-1a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v1m-9-6v3m0-3c1.262 0 2-.968 2-2.625S12 2 12 2s-2 1.718-2 3.375S10.738 8 12 8"/><path d="M9 14a3 3 0 1 1-6 0m12 0a3 3 0 1 1-6 0m12 0a3 3 0 1 1-6 0"/></g></svg>
        );
      case 'phone':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="#404041" d="m224 154.8l-47.09-21.11l-.18-.08a19.94 19.94 0 0 0-19 1.75a13 13 0 0 0-1.12.84l-22.31 19c-13-7.05-26.43-20.37-33.49-33.21l19.06-22.66a12 12 0 0 0 .85-1.15a20 20 0 0 0 1.66-18.83a1.4 1.4 0 0 1-.08-.18L101.2 32a20.06 20.06 0 0 0-20.78-11.85A60.27 60.27 0 0 0 28 80c0 81.61 66.39 148 148 148a60.27 60.27 0 0 0 59.85-52.42A20.06 20.06 0 0 0 224 154.8M176 204A124.15 124.15 0 0 1 52 80a36.29 36.29 0 0 1 28.48-35.54l18.82 42l-19.16 22.82a12 12 0 0 0-.86 1.16A20 20 0 0 0 78 130.08c9.42 19.28 28.83 38.56 48.31 48a20 20 0 0 0 19.69-1.45a12 12 0 0 0 1.11-.85l22.43-19.07l42 18.81A36.29 36.29 0 0 1 176 204"/></svg>
        );
      case 'email': 
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#404041" d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0l-8 5l-8-5zm0 12H4V8l8 5l8-5z"/></svg>
        )  
      default: 
        return null;
    }
  }

  const renderInfoAgencyItem = (text, type) => {
    return (
      <div className="info-agency-item">
        {renderIconInfoAgency(type)}
        <span>{text}</span>
      </div>
    )
  }

  const renderBoxLink = (title,link, type) => {
    return (
      <div className="box-link box-info">
        <p className="title-box-link">{title}</p>
        <div className="d-flex">
          <div className="container-link-text"><a href={link?.trim()?.substring(0,5)?.includes('http') ? link : `https://${link?.trim()}`} target="_blank" className="link-text">{link}</a></div>
          <button className="btn-copy-link" onMouseOver={() => setTypeCopy(type)} onMouseOut={() => setTypeCopy('')} onClick={() => handleCopy(type, link)}>
            {!!isCopied?.[type] ? <IconTick /> : <IconCopy />}
            {typeCopy === type && <span className="copy-text">{!!isCopied?.[type] ? 'Đã sao chép' : 'Sao chép'}</span>}
          </button>
        </div>
        <button className="btn-copy-link-mobile" onClick={() => handleCopy(type, link)}>
            {!!isCopied?.[type] ? <IconTick /> : <IconCopy />}
            {!!isCopied?.[type] ? 'Đã sao chép' : 'Sao chép'}
          </button>
      </div>
    )
  }

  return (
    <div className="bg-main rel">
      <HeaderMain />
      <div className="body-home-container">
        <div className="sunE-container">
          <div className="home-content">
            <div className="box-info-agency box-info">
              <div className="avatar-agency">
                <img
                  src={authentication?.gender === 'male' ? "/assets/images/avatar_male.png" : "/assets/images/avatar_female.png"}
                  alt="Logo Sun-E"
                />
              </div>
              <div className="main-info-agency">
                <p className="name-agency">
                  {authentication.fullname}
                </p>
                <div className="sub-info-agency">
                  {renderInfoAgencyItem(moment(authentication.birthday).format("DD/MM/YYYY"), 'birthday')}
                  {renderInfoAgencyItem(authentication.phone, 'phone')}
                  {renderInfoAgencyItem(authentication.email, 'email')}
                </div>
              </div>
            </div>
            {renderBoxLink('Link Đăng ký tuyến dưới', authentication.downline_register, homeType.REGISTER)}
            {renderBoxLink('Link Landing page cho khách hàng', authentication.promotional_link, homeType.LANDING)}
          </div>
        </div>
      </div>
    </div>
  )
}